/*
|--------------------------------------------------------------------------
| Users management features and utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components for the Users management
| of the application.
*/

// Import Vue.js Framework functions
import { reactive } from 'vue'
import { computed } from 'vue'

// Import librairies
//import axios from 'axios'

// import own utility function library
import useCrudComp from './useCrudComp.js'
import useErrorsComp from '@/composables/useErrorsComp.js'

export default function useUsersComp() {

    // define variable with the needed utils functions
    const useCrud = useCrudComp()
    const useErrors = useErrorsComp()


    // define the reactive variables
    const state = reactive({

        form: {
            body: {
                id: null,
                name: '',
                email: '',
                password: '',
                role: 0,
            },
            show_password: false,
            options: [
                { text: '0: New', value: 0 },
                { text: '1: User', value: 1 },
                { text: '2: Master', value: 2 },
                { text: '3: Admin', value: 3 },
            ],

            status: null,   // this point has to be improved ! !
            resources: null,
            loading: true,

            response: {
                message: null,
                alert: null,
            }

        },
        checkedUsers: [0,1],

        selectedUsers : computed(() => {
            let length = state.checkedUsers.length
            var selectedUsersToShow = []

            for (var i = 0; i < length; i++) {
                selectedUsersToShow[i] = state.checkedUsers[i]
            }

            state.form.resources.sort( compare )

            return state.form.resources.filter(function(usr) {
                return selectedUsersToShow.includes(usr.role)
            })
        })

    })



    // Compare 2 data by specific attributes
    function compare( a, b ) {

        if ( a.name.toLowerCase() < b.name.toLowerCase() ) {
            return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ) {
            return 1;
        }
        return 0;
    }

    // Flush all user's attributes
    function flushAttributes() {
        state.form.body.id = null
        state.form.body.name = 'jose'
        state.form.body.email = 'jose@test.com'
        state.form.body.password = 'coucou'
        state.form.body.role = 0
    }


    function getUsers() {

        state.form.loading = true

        return useCrud.getAllResources({
            url: 'users'
        })
        .then((res) => {
            state.form.resources = res.data.data
            state.form.loading = false
            state.form.status = 'success'
        });
    }


    function storeResource() {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        return useCrud.storeResource({
            url: 'users',
            resource: state.form.body
        })
        .then((res) => {
            state.form.resources.push(res.data.resource)

            state.form.status = 'success'
        })
    /*
        .catch((err) => {
            useErrors.useSetErrors(err.response.data)
        })
    */
    }

    function updateResource() {

        useErrors.useResetErrors()
        state.form.status = 'updating'

        let id = state.form.body.id

        return useCrud.updateResource({
            url: 'users/' + id,
            resource: state.form.body
        })
        .then((res) => {
            var index = state.form.resources.findIndex(x => x.id === res.data.resource.id)

            // if resource not existing in array
            if (index < 0) {
                // add resource to array
                state.form.resources.push(res.data.resource)
            } else {
                // update resource in array
                state.form.resources.splice(index, 1, res.data.resource)
            }

            state.form.status = 'success'

            state.form.response.message = res.data.message
            //state.form.response.alert = 'alert-success'

            //useMessages(res.data, 'alert-success')
        })
        /*
        .catch((err) => {
            useMessages(err.response.data, 'alert-danger')
        })
        */


    }



    function deleteResource(id) {

        return useCrud.deleteResource({
            url: 'users/' + id,
            resource: id
        })
        .then(() => {
            var index = state.form.resources.findIndex(x => x.id === id)
            state.form.resources.splice(index, 1)

            state.form.status = 'success'
        })

    }


    return {
        state,
        //useCrud,
        useErrors,
        //compare,
        flushAttributes,
        getUsers,
        storeResource,
        updateResource,
        deleteResource,
    }

}
