/*
|--------------------------------------------------------------------------
| Date and Time composables utilities
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods used in
| the different components for the manipulation of the Date and Time.
*/

import { reactive } from 'vue'
import { getCurrentInstance } from 'vue'

export default function useDateTimeUtilsComp() {

    const internalInstance = reactive( getCurrentInstance() )


    // Return formated date to display
    function formatedDate(date, lang, format) {

        if (date) {
            let myFormatedDate = internalInstance.appContext.config.globalProperties
                                    .$moment(date).locale(lang).format(format)
            return myFormatedDate
        } else {
            return null
        }
    }

    function getMonday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
        let monday = new Date(date)

        return new Date(monday.setDate(diff))
    }


    function getTuesday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) + 1  // adjust when day is sunday
        let tuesday = new Date(date)

        return new Date(tuesday.setDate(diff))
    }

    function getWednesday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) + 2  // adjust when day is sunday
        let wednesday = new Date(date)

        return new Date(wednesday.setDate(diff))
    }

    function getThursday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) + 3  // adjust when day is sunday
        let thursday = new Date(date)

        return new Date(thursday.setDate(diff))
    }

    function getFriday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) + 4  // adjust when day is sunday
        let friday = new Date(date)

        return new Date(friday.setDate(diff))
    }

    function getSaturday(date) {
        let day = date.getDay()
        let diff = date.getDate() - day + (day == 0 ? -6 : 1) + 5  // adjust when day is sunday
        let saturday = new Date(date)

        return new Date(saturday.setDate(diff))
    }


    function getSunday(date) {
        let day = date.getDay()
        let diff = date.getDate() + 7 - (day == 0 ? 7 : day) // adjust when day is sunday
        let sunday = new Date(date)

        return new Date(sunday.setDate(diff))
    }

    function getWeekNumber(date) {
        let firstJanuary = new Date(date.getFullYear(),0,1)
        let numberOfDays = Math.floor((date - firstJanuary) / (24 * 60 * 60 * 1000))
        let result = Math.ceil(( date.getDay() + 1 + numberOfDays) / 7)
        return result
    }


    function checkWeekDay(date, day) {
        let d = new Date(date)

        if ( (day == 7) && (d.getDay() == 0) ) {
            return 1
        } else {
            if ( d.getDay() == day ) {
                return 1
            } else {
                return 0
            }
        }
    }

    function addOneWeek(date) {
        let diff = date.getDate() + 7
        return new Date(date.setDate(diff))
    }

    function substractOneWeek(date) {
        let diff = date.getDate() - 7
        return new Date(date.setDate(diff))
    }

    function addWeeks(date, n) {
        let nbWeeks = n * 7
        let diff = date.getDate() + nbWeeks
        return new Date(date.setDate(diff))
    }

    function addDays(date, n) {
        let nbDays = n
        let diff = date.getDate() + nbDays
        return new Date(date.setDate(diff))
    }

    function addMonths(date, n) {
        return new Date(date.setMonth(date.getMonth()+n));
    }

    return {
        formatedDate,
        getMonday,
        getTuesday,
        getWednesday,
        getThursday,
        getFriday,
        getSaturday,
        getSunday,
        getWeekNumber,
        checkWeekDay,
        addOneWeek,
        substractOneWeek,
        addDays,
        addWeeks,
        addMonths,
    }

}
